import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import AccountGate from "~/components/account/AccountGate"
import { useApplicationState } from "~/hooks/useApplicationState"
import { usePrinticularApi } from "~/hooks/usePrinticularApi"
import { ReactComponent as EmailSent } from "~/images/EmailSent.svg"
import ErrorAlert from "../components/ErrorAlert"

type FormData = {
  emailAddress: string
}

const ForgotPassword = () => {
  const api = usePrinticularApi()
  const { baseUrl } = useApplicationState()
  const { t } = useTranslation()
  const [error, setError] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data: FormData) => {
    setIsLoading(true)

    try {
      const { emailAddress } = data
      await api.forgotPassword({
        emailAddress,
        siteUrl: baseUrl,
      })
      setIsFormValidated(true)
    } catch (error) {
      setError(error)
    }

    setIsLoading(false)
  }

  return (
    <AccountGate>
      <Box my={{ base: 0, md: 20 }} mx="auto" maxWidth="100%" width="2xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={6}>
            <Box>
              <Heading as="h1" size="lg">
                {t("pages.forgot-password.ForgotPassword")}
              </Heading>
              {!isFormValidated && (
                <Text m={0} mt={3} fontSize="md">
                  {t("pages.forgot-password.ForgotMessage")}
                </Text>
              )}
            </Box>

            {error && !isFormValidated && (
              <ErrorAlert data={error} displayErrorsList={false} />
            )}

            {!isFormValidated && (
              <>
                <FormControl isInvalid={!!errors.emailAddress}>
                  <Input
                    id="forgot-password-email-address"
                    type="email"
                    autoComplete="email"
                    placeholder={t("pages.forgot-password.Email")}
                    variant="filled"
                    {...register("emailAddress", {
                      required: t<string>("pages.forgot-password.Required"),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("pages.forgot-password.InvalidEmail"),
                      },
                    })}
                  />
                  {!!errors.emailAddress && (
                    <FormErrorMessage>
                      {errors.emailAddress.message}
                    </FormErrorMessage>
                  )}
                </FormControl>

                <Box>
                  <Button
                    id="forgot-password"
                    colorScheme="primary"
                    color="primary.text"
                    type="submit"
                    isLoading={isLoading}
                  >
                    {t("pages.forgot-password.Submit")}
                  </Button>
                </Box>
              </>
            )}

            {isFormValidated && <SuccessForgotPassword />}
          </Stack>
        </form>
      </Box>
    </AccountGate>
  )
}

export default ForgotPassword

const SuccessForgotPassword = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Alert status="success" borderRadius={4}>
        <AlertIcon />
        <AlertDescription fontSize={{ base: "sm", lg: "md" }}>
          {t("pages.forgot-password.Success")}
        </AlertDescription>
      </Alert>
      <Box width="80%" mx="auto" mt={30}>
        <EmailSent />
      </Box>
    </Box>
  )
}
